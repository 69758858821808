@import url(https://fonts.googleapis.com/css?family=Open+Sans);

body{
  background: #4cd884;
  font-family: 'Open Sans', sans-serif;
}

.search {
  width: 100%;
  position: relative;
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 3px solid #000000;
  border-right: none;
  padding: 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9DBFAF;
}

.searchTerm:focus{
  color: #000000;
}

.searchButton {
  width: 50px;
  height: 36px;
  border: 1px solid #000000;
  background: #000000;
  text-align: center;
  color: rgb(255, 255, 255);
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/
.wrap{
  width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrap{
  width: 30%;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.resultsWrap{
  width: 100%;
  position: absolute;
  top: 30%;
  left: 25%;
}

.productCard{
  border: 2px solid #000000;
  background-color: blanchedalmond;
  margin-top: 1%;
  width: 50%;
  text-align: center;
}